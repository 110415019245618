@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    font-family: 'Open Sans', sans-serif;
    overflow-y: scroll;
    overflow-x: hidden;
}

@keyframes heartbeat {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(0.75);
    }
    40% {
        transform: scale(1);
    }
    60% {
        transform: scale(0.75);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

.chevron-icon {
    transform-origin: center;
    transition: transform 0.3s ease;
}

.group:hover .chevron-icon {
    transform: rotate(180deg);
}
